import { api } from 'shared/api/baseApi';
import { IUser } from 'shared/types/IUser';

import {IUserEditInfo, IUserEditPassword} from "features/profile/edit/types";

export const editUserInfoApi = api.injectEndpoints({
    endpoints: (build) =>  ({
        editInfo: build.mutation<IUser, IUserEditInfo>({
            query: (user) => ({
                url: 'cabinet/user_info_edit',
                method: 'POST',
                body: user,
                credentials: 'include'
            })
        }),
        editPassword: build.mutation<void, IUserEditPassword>({
            query: (password) => ({
                url: 'cabinet/change_password',
                method: 'POST',
                body: password,
                credentials: 'include'
            })
        }),
        editAvatar: build.mutation<IUser, any>({
            query: (data) => ({
                url: 'user/avatar/upload',
                method: 'POST',
                body: data,
                credentials: 'include',
                headers: {
                    "Content-Type": undefined,
                }
            })
        }),
    })
});
export const {
    useEditInfoMutation,
    useEditPasswordMutation,
    useEditAvatarMutation
} = editUserInfoApi;



