import { api } from 'shared/api/baseApi';

interface IWorkplace {
    workplace_id: number
}

export const manageDayApi = api.injectEndpoints({
    endpoints: (build) =>  ({
        getButtonPosition: build.query<any, string>({
            query: () => ({
                url: 'get_button_position',
                credentials: "include",
            })
        }),
        startDay: build.mutation<void, IWorkplace>({
            query: (workplace_id) => ({
                url: 'start_day',
                method: 'POST',
                body: workplace_id,
                credentials: "include"
            })
        }),
        pauseDay: build.mutation<void, void>({
            query: () => ({
                url: 'pause_day',
                method: 'POST',
                credentials: "include"
            })
        }),
        resumeDay: build.mutation<void, IWorkplace>({
            query: (workplace_id) => ({
                url: 'resume_day',
                method: 'POST',
                body: workplace_id,
                credentials: "include"
            })
        }),
        endDay: build.mutation({
            query: (without_lunch = false) => ({
                url: 'end_day',
                method: 'POST',
                body: without_lunch,
                credentials: "include"
            })
        }),
    })
});
export const {
    useGetButtonPositionQuery,
    useStartDayMutation,
    usePauseDayMutation,
    useResumeDayMutation,
    useEndDayMutation
} = manageDayApi;



