import { FC } from 'react';
import cnBind from 'classnames/bind';
import { ErrorMessage, Field } from 'formik';

import { InputDateProps } from "shared/ui/input/types";
import styles from 'shared/ui/input/Input.module.scss';

export const InputDate:FC<InputDateProps> = ({name, label}) => {
    const cx = cnBind.bind(styles);
    return (
        <>
            <label htmlFor={name} className={styles.label}>{label}</label>
            <Field className={cx(styles.input_date, styles.input)}  name={name} type="date"></Field>
            <ErrorMessage component="div" className={styles.error} name={name}/>
        </>
    );
};

