import { Outlet } from "react-router";

import { Header, Navbar } from "widgets/layout";

export const RootLayout = () => {
    return (
        <div className="App">
            <div className="app-content d-flex">
                <Navbar/>
                <main>
                    <Header/>
                    <Outlet/>
                </main>
            </div>
        </div>
    );
};
