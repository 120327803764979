export const fetchReport = async (month: number, url: string, fileName:string) => {

    const response = await fetch(`${process.env.REACT_APP_API_URL}${url}?month=${month}`, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
    });
    const responseClone = await response.clone();
    const responseText = await responseClone.text();

    if(responseText === 'false') {
        return false;
    } else {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.xlsx`);
        document.body.appendChild(link);
        link.click();
        return true;
    }
};
