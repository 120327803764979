import React, { FC, useState } from 'react';
import * as Yup from 'yup';
import {  Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { useNavigate } from "react-router-dom";

// пофиксить этот импорт, не соотвествие FSD
import {useAppDispatch, useAppSelector} from "app/store/hooks";

import { regex } from 'shared/lib/regex';
import { Button, Input, Select, CloseButton, Overlay, FormError, InputPassword, InputDate } from 'shared/ui';
import { setUser } from "shared/lib/store/user";

import { useCreateUserMutation, useFetchAllDepartmentsQuery, useFetchAllPositionsQuery } from "features/registration/api";
import { setRegistrationFormVisible } from "features/registration/model";
import { IRegistrationUser } from "features/registration/types";

import styles from 'features/registration/ui/RegistrationForm.module.scss';

export const RegistrationForm:FC = () => {

    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const visible = useAppSelector(state => state.registrationSlice.isRegistrationFormVisible);

    const [selected, setSelected] = useState<number | null>(null);
    const [error, setError] = useState(false);

    const departments = useFetchAllDepartmentsQuery();
    const positions = useFetchAllPositionsQuery(selected);
    const [ createUser ] = useCreateUserMutation();

    const handleChange = (event:  React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOptionId = parseInt(event.target.value);
        setSelected(selectedOptionId);
    };

    const handleSubmit = async (values: IRegistrationUser) => {
        await createUser(values).unwrap()
            .then(res => {
                if(res) {
                    localStorage.setItem('User', JSON.stringify(res.user));
                    localStorage.setItem('token', JSON.stringify(res.token));
                    dispatch(setUser(res.user));
                    dispatch(setRegistrationFormVisible());
                    navigate("/");
                } else {
                    setError(true);
                }
            });
    };

    return (
        <>
            <Overlay trigger={visible}/>
            <Formik
                initialValues={{
                    lastname: '',
                    firstname: '',
                    birthday: '',
                    phone: '',
                    department: '',
                    position_id: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                }}
                validationSchema={Yup.object({
                    lastname: Yup.string()
                        .min(2, 'Минимум 2 символа для заполнения!')
                        .matches(regex.name, {
                            message: 'Только буквы!'
                        })
                        .required('Обязательное поле!'),
                    firstname: Yup.string()
                        .min(3, 'Минимум 3 символа для заполнения!')
                        .matches(regex.name, {
                            message: 'Только буквы!'
                        })
                        .required('Обязательное поле!'),
                    birthday: Yup.date()
                        .required('Обязательное поле!'),
                    phone: Yup.string()
                        .min(11, 'Неверный формат номера!')
                        .matches(regex.phone, {
                            message: 'Неверный формат номера!'
                        })
                        .required('Обязательное поле'),
                    department: Yup.string()
                        .required('Обязательное поле'),
                    position_id: Yup.string()
                        .required('Обязательное поле'),
                    email: Yup.string()
                        .email('Неверный формат электронной почты!')
                        .required('Обязательное поле!'),
                    password: Yup.string()
                        .required('Обязательное поле!')
                        .min(6, 'Минимум 6 символов'),
                    password_confirmation: Yup.string()
                        .required('Обязательное поле!')
                        .oneOf([Yup.ref('password')], 'Пароли не совпадают')
                        .min(6, 'Минимум 6 символов')
                })}
                onSubmit={(values) => {
                    handleSubmit(values);
                }}>
                <Form className={visible ? styles.form_active : styles.form}>

                    <h2 className={styles.title}>Регистрация</h2>
                    <CloseButton onClick={() => dispatch(setRegistrationFormVisible())}/>
                    <Row>
                        <Col sm={12}>
                            <Input
                                type={'text'}
                                name={'lastname'}
                                placeholder={'Иванов'}
                                label={'Фамилия'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Input
                                type={'text'}
                                name={'firstname'}
                                placeholder={'Иван'}
                                label={'Имя'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <InputDate
                                name={'birthday'}
                                label={'Дата рождения'}
                            />
                        </Col>
                        <Col sm={6}>
                            <Input
                                type={'text'}
                                name={'phone'}
                                placeholder={'79504223321'}
                                label={'Телефон'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <Select
                                label="Отдел"
                                name="department"
                                onClick={(event) => handleChange(event)}
                                defaultOption={true}
                            >
                                {
                                    departments.data?.map(item => {
                                    return (
                                        <option className={styles.option} key={item.id} value={item.id}>{item.name}</option>
                                    );
                                    })
                                }
                            </Select>
                        </Col>
                        <Col sm={6}>
                            <Select
                                label="Должность"
                                name="position_id"
                                defaultOption={true}
                            >
                                {
                                    positions.data?.map(item => {
                                        return (
                                            <option className={styles.option} key={item.id} value={item.id}>{item.name}</option>
                                        );
                                    })
                                }
                            </Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Input
                                type={'text'}
                                name={'email'}
                                placeholder={'ivanov@gmail.com'}
                                label={'Электронная почта'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <InputPassword
                                type={'password'}
                                name={'password'}
                                label={'Пароль'}
                            />
                        </Col>
                        <Col sm={6}>
                            <InputPassword
                                type={'password'}
                                name={'password_confirmation'}
                                placeholder={''}
                                label={'Введите пароль еще раз'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <FormError text={'Пользователь с такой почтой/номером телефона уже существует'} visible={error}/>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Button text={'Зарегистрироваться'} color={'blue'}/>
                        </Col>
                    </Row>
                </Form>
            </Formik>
        </>
    );
};
