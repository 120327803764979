import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IUser, IUserWithToken} from "shared/types/IUser";


interface IState {
    user: IUser,
    userWorkplace: string
}

const initialState:IState = {
        user:{
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            birthday: '',
            id: 0,
            quote: '',
            position: {
                id: 0,
                name: ''
            },
            department: {
                id: 0,
                name: ''
            },
            verified: 0,
            photo: null,
            modules: []
        },
    userWorkplace: ''
};



export const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        setUser: (state, action:PayloadAction<IUser>) => {
            state.user = action.payload;
        },
        setUserWorkplace: (state, action:PayloadAction<string>) => {
            state.userWorkplace = action.payload;
        },
        logOutUser: (state, action:PayloadAction) => {
            state.user = initialState.user;
        }
    }
});

export const { setUser, setUserWorkplace, logOutUser} = userSlice.actions;
export default  userSlice.reducer;



