import { Navigate } from 'react-router-dom';

import { useAuth } from 'shared/lib/hooks';

interface  ProtectedRouteProps {
    children: React.ReactNode;
}

export const ProtectedRoute = ({children}: ProtectedRouteProps) => {
    const isAuth = useAuth();
    return isAuth ? <>{children}</> : <Navigate to={'/auth'}/>;
};
