import { Container } from "react-bootstrap";

import { ManageDay } from "features/manageDay";
import { Time } from 'shared/ui';


import styles from 'pages/home/ui/HomePage.module.scss';



export const HomePage = () => {
    return (
        <Container>
            <div className={styles.wrapper}>
                <div className={styles.button_wrapper}>
                   <ManageDay/>
                    <Time/>
                </div>
            </div>

        </Container>
    );
};

