import { FC } from 'react';
import { InputProps } from "shared/ui/input/types";
import styles from 'shared/ui/input/Input.module.scss';
import {ErrorMessage, Field} from "formik";
export default function TextArea({ type, placeholder, name, label}: InputProps) {
    return (
        <>
            <label htmlFor={name} className={styles.label}>{label}</label>
            <Field className={styles.input}  name={name} type={type} placeholder={placeholder} as="textarea"></Field>
            <ErrorMessage component="div" className={styles.error} name={name}/>
        </>
    );
}