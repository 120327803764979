import { FC } from 'react';
import cnBind from 'classnames/bind';

import styles from 'features/exit/ui/Exit.module.scss';

const cx = cnBind.bind(styles);
interface IExitProps  {
    visible: string,
    onClick: () => void
}
export const Exit:FC<IExitProps> = ({visible, onClick}) => {
    const exitClass = cx(styles.exit);
    return (
        <div className={cx(exitClass, visible)} onClick={onClick}>Выйти</div>
    ) ;
};
