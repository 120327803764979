import { FC } from 'react';
import cnBind from 'classnames/bind';

import { ButtonProps } from 'features/manageDay/types';

import styles from 'features/manageDay/ui/ManageDayButton.module.scss';

export const ManageDayButton:FC<ButtonProps> = ({color, text, onClick, disabled, icon}) => {

    const cx = cnBind.bind(styles);

    return (
        <button className={disabled ? cx(styles.button, color, disabled, icon) : cx(styles.button, color, icon)} disabled={disabled} onClick={onClick}>{text}</button>
    );
};
