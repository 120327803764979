import { Link, useNavigate } from 'react-router-dom';
import cnBind from 'classnames/bind';

import { Exit } from 'features/exit';
import { Overlay } from 'shared/ui';
import { Coworkers, Duty, Comment, Requests, BugTracker } from 'shared/ui/icons';
import { NavLink } from 'features/navLink';
//fix this import
import { useAppDispatch, useAppSelector } from "app/store/hooks";
import { useAuth } from 'shared/lib/hooks';
import { getUserWorkplace } from "shared/lib";
import { useLogoutUserMutation } from "shared/api/user";
import { setNameToAvatar } from "shared/lib";
import { closeBurger } from "widgets/layout/model/layoutSlice";
import { logOutUser } from "shared/lib/store/user";

import styles from 'widgets/layout/ui/Navbar.module.scss';

export const Navbar = () => {
    const cx = cnBind.bind(styles);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isAuth = useAuth();

    const [logoutUser] = useLogoutUserMutation();

    const data = JSON.parse(localStorage.getItem('User') || '{}');

    const user = useAppSelector(state => state.userSlice.user);
    const isBurgerOpen = useAppSelector(state => state.layoutSlice.isOpen);
    const stateWorkplace= useAppSelector(state => state.userSlice.userWorkplace);

    const logout = async () => {
        dispatch(closeBurger());
        await logoutUser()
            .then(() => {
                localStorage.removeItem('token');
                localStorage.removeItem('User');
                dispatch(logOutUser());
                navigate('/auth');
            });
    };


    const navClass = cx(styles.nav, {
        'nav-active': isBurgerOpen
    });

    return (
        <>
            {
                isAuth ? (
                    <>
                        <Overlay trigger={isBurgerOpen}/>
                        <nav className={cx(navClass)}>
                            <div className={styles.nav_links}>
                                <NavLink text={'Мои коллеги'} link={'/coworkers'} onClick={() => dispatch(closeBurger())}><Coworkers/></NavLink>
                                <NavLink text={'Баг-трекер'} target={"_blank"} link={'https://outmax-office.ru/requests'} onClick={() => dispatch(closeBurger())}><BugTracker/></NavLink>
                                <NavLink text={'Комментарий'} link={'/comment'} onClick={() => dispatch(closeBurger())}><Comment/></NavLink>
                                <NavLink text={'Заявки'} link={'/requests'} onClick={() => dispatch(closeBurger())}><Requests/></NavLink>
                                <NavLink text={'Дежурство'} link={'/duty'} onClick={() => dispatch(closeBurger())}><Duty/></NavLink>
                            </div>
                            <div className={styles.nav_profile} onClick={() => dispatch(closeBurger())}>
                                {data.photo ? (
                                    <Link to="/profile" className={styles.avatar}>
                                        <img src={data.photo} alt={'аватар'}/>
                                    </Link>
                                ) : (
                                    <Link to="/profile" className={styles.thumbnail}>{setNameToAvatar(data.firstname, data.lastname)}</Link>
                                )}
                                <div className={styles.info}>
                                    <Link to="/profile" className={styles.name}>{data?.firstname}</Link>
                                    <span className={styles.workplace}>Рабочее место: <span className={styles.workplace_color}>{getUserWorkplace(stateWorkplace)}</span></span>
                                    <Exit visible={'visible'} onClick={() => logout()}/>
                                </div>
                            </div>
                            <Exit visible={'non-visible'} onClick={() => logout()}/>
                        </nav>
                    </>
                ): null
            }

        </>
    );
};
