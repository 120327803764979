import { IRegistrationUser } from "features/registration/types";

import { IUser } from "shared/types/IUser";
import { IDepartments, IPositions } from "shared/types/IPositionIDepartments";
import { api } from 'shared/api/baseApi';

export const registrationApi = api.injectEndpoints({
    endpoints: (build) =>  ({
        createUser: build.mutation<any, any>({
            query: (user) => ({
                url: 'register',
                method: 'POST',
                body: user,
                credentials: 'include'
            })
        }),
        fetchAllDepartments: build.query<IDepartments[], void>({
            query: () => 'get_departments'
        }),
        fetchAllPositions: build.query<IPositions[], number | null>({
            query: (selected: number | null) => ({
                url: 'get_positions',
                params: {
                    department_id: selected
                }
            })
        }),
    })
});
export const {
    useCreateUserMutation,
    useFetchAllDepartmentsQuery,
    useFetchAllPositionsQuery
} = registrationApi;

// <IUser, IRegistrationUser>
