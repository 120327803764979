import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import { ProtectedRoute } from 'app/ProtectedRoute';
import { RootLayout } from "app/RootLayout";
import { HomePage } from "pages/home";
import { ProfilePage } from "pages/profile";
import { AuthPage } from "pages/auth";
import { NotFoundPage } from 'pages/404';
import { CoworkersPage } from 'pages/coworkers';
import { CommentPage } from "pages/comment";
import { InDevelopmentPage } from "pages/inDevelopment";

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path='/' element={<RootLayout/>}>
            <Route path='*' element={<NotFoundPage/>}/>
            <Route path='/auth' element={<AuthPage/>}/>
            <Route index element={<ProtectedRoute children={<HomePage/>}/>}/>
            <Route path='/profile' element={<ProtectedRoute children={<ProfilePage/>}/>}/>
            <Route path='/coworkers' element={<ProtectedRoute children={<CoworkersPage/>}/>}/>
            <Route path='/duty' element={<ProtectedRoute children={<InDevelopmentPage/>}/>}/>
            <Route path='/comment' element={<ProtectedRoute children={<CommentPage/>}/>}/>
            <Route path='/requests' element={<ProtectedRoute children={<InDevelopmentPage/>}/>}/>
        </Route>
    )
);

