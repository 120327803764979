import React, { useState } from "react";
import { Formik, Form } from 'formik';

import { months } from "features/timeReport/consts/months";
import { fetchReport} from "features/timeReport/lib/fetchReport";

import { Select, Button } from "shared/ui";

import styles from 'features/timeReport/ui/TimeReport.module.scss';
interface Props {
    url: string,
    fileName: string,
    title: string
}
export const BaseTimeReport = ({url, fileName, title}:Props) => {

    const currentMonthIndex:number = new Date().getMonth() + 1;

    const [month, setMonth] = useState(currentMonthIndex);
    const [error, setError] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setMonth(Number(event.target.value));
        setError(false);
    };


    return (
        <div className={styles.block}>
            <span className={styles.title}>{title}</span>
            <Formik
                initialValues={{
                    month: currentMonthIndex,
                }}
                onSubmit = {() =>{
                    fetchReport(month, url, fileName).then(res => res ? setError(false) : setError(true));
                }}
            >
                <Form>
                    <Select
                        label={'Выберите интересующий вас месяц для отчёта'}
                        name={'month'}
                        onClick={(event: React.ChangeEvent<HTMLSelectElement>) => handleChange(event)}
                    >
                        {
                            months.map(item => <option value={item.value} key={item.value}>{item.title}</option>)
                        }
                    </Select>
                    <span className={error ? styles.error_active :  styles.error}>Не удалось скачать файл! Нет записей для этого месяца!</span>
                    <Button color={'blue'} text={'Скачать'} />
                </Form>
            </Formik>
        </div>
    );
};
