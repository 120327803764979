import { FC } from "react";

import styles from 'entities/user/ui/UserInfo.module.scss';

interface IUserInfoProps {
    title: string,
    info: string | undefined
}
export const UserInfo:FC<IUserInfoProps> = ({title, info}) => {
  return (
      <>
          <span className={styles.title}>{title}</span>
          <span className={styles.info}>{info}</span>
      </>
  );
};
