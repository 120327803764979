// фикс импорта
import { useAppSelector } from "app/store/hooks";

import { UserInfo, UserQuote } from 'entities/user';
import { ProfileEditForm, PasswordChangeForm, PasswordChangeButton, ProfileEditButton, UserAvatar, UserAvatarForm } from "features/profile/edit";

import { useGetUserQuery} from "shared/api/user";
import { Loader } from "shared/ui";

import styles from 'widgets/user/ui/User.module.scss';

export const User = () => {
    const userInfo = useAppSelector(state => state.userSlice.user);
    const { data, isLoading } = useGetUserQuery(userInfo);

    return (
        <>
            {
                isLoading ? (
                <Loader/> ): (
                    <>
                        <div className={styles.wrapper}>
                            <UserAvatar/>
                            <div className={styles.block}>
                                <UserInfo title={'Дата рождения'} info={data?.birthday}/>
                            </div>
                            <div className={styles.block}>
                                <UserInfo title={'Отдел'} info={data?.department.name}/>
                            </div>
                            <div className={styles.block}>
                                <UserInfo title={'Должность'} info={data?.position.name}/>
                            </div>
                            <div className={styles.block}>
                                <UserInfo title={'Телефон'} info={data?.phone}/>
                            </div>
                            <div className={styles.block}>
                                <UserInfo title={'Электронная почта'} info={data?.email}/>
                            </div>
                            <div className={styles.block}>
                                <UserInfo title={'Пароль'} info={'*******'}/>
                                <PasswordChangeButton/>
                            </div>
                            <UserQuote quote={data?.quote}/>
                            <ProfileEditButton/>
                        </div>
                        <ProfileEditForm/>
                        <PasswordChangeForm/>
                        <UserAvatarForm/>
                </>
                )
            };
        </>

    );

};
