import { useEffect, useState } from "react";

import styles from 'shared/ui/time/Time.module.scss';
export const Time = () => {

    const [time, setTime] = useState('');

    const setTransformTime = (time: number) => time < 10 ? '0' + time : time;
    const changeTime = () => {
        const date = new Date();
        const hours = setTransformTime(date.getHours());
        const minutes = setTransformTime(date.getMinutes());
        const seconds = setTransformTime(date.getSeconds());
        setTime(`${hours}:${minutes}:${seconds}`);
    };

    useEffect(() => {
        const timer = setInterval(() => changeTime());
        return () => clearInterval(timer);
    },[time]);

    return (
        <span className={styles.time}>{time}</span>
    );
};
