import { BaseTimeReport } from "features/timeReport/ui/BaseTimeReport";

export const FullTimeReport = () => {
    return (
        <BaseTimeReport
            url={'cabinet/download_full_report'}
            fileName={'подробный_отчет_сотрудников'}
            title={'Подробный отчёт рабочего времени для всех сотрудников'}
        />
    );
};
