import { FC } from 'react';

import styles from 'shared/ui/overlay/Overlay.module.scss';

interface IOverlayProps  {
    trigger: boolean
}
export const Overlay:FC<IOverlayProps> = ({trigger}) => {
    return (
        <div className={trigger ? styles.overlay_active : styles.overlay}></div>
    );
};
