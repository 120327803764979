import type { SVGProps } from "react";
const SvgCoworkers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2.5em"
    height="2.5em"
    fill="none"
    viewBox="0 0 36 35"
    {...props}
  >
    <g fill="#E2E2E2" clipPath="url(#coworkers_svg__a)">
      <path
        fillRule="evenodd"
        d="M24.81 19.148c1.998 1.356 3.399 3.194 3.399 5.644v4.375h5.833v-4.375c0-3.18-5.207-5.06-9.232-5.644Z"
        clipRule="evenodd"
      />
      <path d="M13.624 17.5a5.833 5.833 0 1 0 0-11.667 5.833 5.833 0 0 0 0 11.667Z" />
      <path
        fillRule="evenodd"
        d="M22.375 17.5a5.832 5.832 0 0 0 5.834-5.833 5.832 5.832 0 0 0-5.834-5.833c-.685 0-1.327.145-1.94.35a8.72 8.72 0 0 1 0 10.966c.613.204 1.255.35 1.94.35ZM13.625 18.959c-3.894 0-11.667 1.954-11.667 5.833v4.375h23.333v-4.375c0-3.88-7.773-5.834-11.666-5.834Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="coworkers_svg__a">
        <path fill="#fff" d="M.5 0h35v35H.5z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCoworkers;
