import { Container } from "react-bootstrap";

import { CommentForm } from 'features/comment';
import {SuccessModal} from "entities/successModal";

export const CommentPage = () => {
    return (
        <Container>
            <CommentForm/>
            <SuccessModal/>
        </Container>

    );
};