import { api } from 'shared/api/baseApi';

import { IUser, IUserWithToken } from "shared/types/IUser";

export const userApi = api.injectEndpoints({
    endpoints: (build) =>  ({
        getUser: build.query<IUser, IUser | null>({
            query: () => ({
                url: 'get_user',
                credentials: "include"
            })
        }),
        logoutUser: build.mutation<void, void>({
            query: () => ({
                url: 'logout',
                method: 'POST',
                credentials: "include"
            })
        }),
    })
});
export const {
    useGetUserQuery,
    useLogoutUserMutation
} = userApi;



