import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import cnBind from 'classnames/bind';

import { useAppDispatch, useAppSelector } from 'app/store/hooks';

import { useAuth } from "shared/lib/hooks";
import { HeaderInfo } from "entities/headerInfo";

import { changeVisible } from 'widgets/layout/model/layoutSlice';

import logo from 'shared/assets/img/logo.svg';

import styles from 'widgets/layout/ui/Header.module.scss';

export const Header = () => {

    const dispatch = useAppDispatch();
    const isAuth = useAuth();

    const isBurgerOpen = useAppSelector(state => state.layoutSlice.isOpen);
    const user = useAppSelector(state => state.userSlice.user);
    const cx = cnBind.bind(styles);

    const btnClass = cx(styles.burger, {
        'burger-active': isBurgerOpen
    });

    useEffect(() => {
        isBurgerOpen ?  document.body.style.overflow = 'hidden' :  document.body.style.overflow = '';
    }, [isBurgerOpen]);

    return (
        <div className={styles.header}>
            <Link to="/" className={styles.logo}><img src={logo} alt="logo"></img></Link>
            <HeaderInfo/>

            {
                isAuth ? (
                   <>
                       <div className={cx(btnClass)} onClick={() => dispatch(changeVisible())}>
                           <span></span>
                       </div>
                   </>
                ): null
            }
        </div>
    );
};
