import { AuthButton } from "features/auth";
import { AuthForm } from "features/auth";
import { RegistrationButton } from "features/registration";
import { RegistrationForm } from 'features/registration';

import styles from 'pages/auth/ui/AuthPage.module.scss';

export const AuthPage = () => {

    return (
       <div className={styles.wrapper}>
           <div className={styles.button_wrapper}>
               <AuthButton/>
               <AuthForm/>
               <RegistrationButton/>
               <RegistrationForm/>
           </div>
       </div>
    );
};

