import { Row, Col } from 'react-bootstrap';
import { useEffect } from 'react';

import { CoworkersItem } from "entities/coworkersItem";
import { CoworkerArrow, CoworkerPopup } from "features/coworkers";
import { useGetUsersQuery } from 'widgets/coworkersList/api';
import { Loader } from "shared/ui";

import styles from 'widgets/coworkersList/ui/CoworkersList.module.scss';


export const CoworkersList = () => {

    const { data, isLoading, refetch } = useGetUsersQuery();

    useEffect(() => {
        refetch();
    }, []);

    return (
        <div className={styles.wrapper}>
            <div className={styles.titles}>
                <Row>
                    <Col xl={3} lg={5} md={7} sm={6} xs={12}>
                        <span className={styles.title}>Сотрудник</span>
                    </Col>
                    <Col xl={1} className={'d-xl-block d-none'}>
                        <span className={styles.title}>Отдел</span>
                    </Col>
                    <Col xl={2} lg={3} md={3} className={'d-md-block d-none'}>
                        <span className={styles.title}>Номер телефона</span>
                    </Col>
                    <Col xl={2} lg={2} className={'d-lg-block d-none'}>
                        <span className={styles.title}>Дата рождения</span>
                    </Col>
                    <Col xl={1} lg={2} md={2} className={'d-md-block d-none'}>
                        <span className={styles.title}>Место</span>
                    </Col>
                    <Col xl={3} className={'d-xl-block d-none'}>
                        <span className={styles.title}>Цитата</span>
                    </Col>
                </Row>
            </div>
               {
                   isLoading ? (
                      <Loader/>
                   ) : (
                       data?.map(item => {
                           return (
                               <div className={styles.item} key={item.id}>
                                   <CoworkersItem  {...item}/>
                                   <CoworkerArrow id={item.id}/>
                               </div>
                           );
                       })
                   )
               }
               <CoworkerPopup coworkers={data}/>
        </div>
    );
};
