import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IState {
    isVisible: boolean
}

const initialState:IState = {
    isVisible: false
};

export const successSlice = createSlice({
    name: 'successSlice',
    initialState,
    reducers: {
        setVisible: (state, action:PayloadAction<boolean>) => {
            state.isVisible = action.payload;
        }
    }
});

export const { setVisible } = successSlice.actions;
export default  successSlice.reducer;



