import { Container, Row, Col } from "react-bootstrap";

import { User } from "widgets/user";
import { TimeReports } from "widgets/timeReports";

export const ProfilePage = () => {
    return (
        <Container>
            <Row>
                <Col xxl={3} xl={4} lg={4} md={6}>
                    <User/>
                </Col>
                <Col xxl={3} xl={4} lg={4} md={6}>
                    <TimeReports/>
                </Col>
            </Row>
        </Container>

    );
};

