import React, { FC } from 'react';
import * as Yup from 'yup';
import {  Row, Col } from 'react-bootstrap';
import { Formik, Form, } from 'formik';

// пофиксить этот импорт, не соотвествие FSD
import {useAppDispatch, useAppSelector} from "app/store/hooks";

import { setProfileFormVisible } from "features/profile/edit/model/store";
import { useEditInfoMutation } from "features/profile/edit/api";
import { IUserEditInfo} from "features/profile/edit/types";

import { regex } from 'shared/lib/regex';
import { Button, Input, CloseButton, Overlay, TextArea, InputDate } from 'shared/ui';
import { IUser } from "shared/types/IUser";
import { setUser } from 'shared/lib/store/user';

import styles from './ProfileEditForm.module.scss';

export const ProfileEditForm:FC = () => {

    const dispatch = useAppDispatch();
    const visible = useAppSelector(state => state.profileEditSlice.isProfileFormVisible);

    const user: IUser = JSON.parse(localStorage.getItem('User') || '{}');

    const [ editInfo ] = useEditInfoMutation();

    const handleSubmit = async (values: IUserEditInfo) => {
        await editInfo(values).unwrap()
            .then(res => {
                dispatch(setUser(res));
                localStorage.setItem('User', JSON.stringify(res));
                dispatch(setProfileFormVisible());
            });
    };

    return (
        <>
            <Overlay trigger={visible}/>
            <Formik
                initialValues={{
                    lastname: user.lastname,
                    firstname: user.firstname,
                    birthday: user.birthday,
                    phone: user.phone,
                    email: user.email,
                    quote: user.quote ? user.quote : ''
                }}
                validationSchema = {Yup.object({
                    lastname: Yup.string()
                        .min(2, 'Минимум 2 символа для заполнения!')
                        .matches(regex.name, {
                            message: 'Только буквы!'
                        })
                        .required('Обязательное поле!'),
                    firstname: Yup.string()
                        .min(3, 'Минимум 3 символа для заполнения!')
                        .matches(regex.name, {
                            message: 'Только буквы!'
                        })
                        .required('Обязательное поле!'),
                    birthday: Yup.date()
                        .required('Обязательное поле!'),
                    phone: Yup.string()
                        .min(11, 'Неверный формат номера!')
                        .matches(regex.phone, {
                            message: 'Неверный формат номера!'
                        })
                        .required('Обязательное поле'),
                    email: Yup.string()
                        .email('Неверный формат электронной почты!')
                        .required('Обязательное поле!'),
                    quote: Yup.string()
                        .required('Обязательное поле')
                        .max(150, 'Слишком длинная цитата')

                })}
                onSubmit={(values, {resetForm}) => {
                    handleSubmit(values);
                }}>
                <Form className={visible ? styles.form_active : styles.form}>

                    <h2 className={styles.title}>Редактирование профиля</h2>
                    <CloseButton onClick={() => dispatch(setProfileFormVisible())}/>
                    <Row>
                        <Col lg={12}>
                            <Input
                                type={'text'}
                                name={'lastname'}
                                placeholder={'Иванов'}
                                label={'Фамилия'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Input
                                type={'text'}
                                name={'firstname'}
                                placeholder={'Иван'}
                                label={'Имя'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <InputDate
                                name={'birthday'}
                                label={'Дата рождения'}
                            />
                        </Col>
                        <Col lg={6}>
                            <Input
                                type={'text'}
                                name={'phone'}
                                placeholder={'79504223321'}
                                label={'Телефон'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Input
                                type={'text'}
                                name={'email'}
                                placeholder={'ivanov@gmail.com'}
                                label={'Электронная почта'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <TextArea
                                type={'text'}
                                name={'quote'}
                                placeholder={'Напишите цитату'}
                                label={'Цитата'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Button text={'Сохранить'} color={'blue'}/>
                        </Col>
                    </Row>
                </Form>
            </Formik>
        </>

    );
};
