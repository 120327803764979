import { RouterProvider } from "react-router-dom";

import { router } from "app/router";

import 'app/style/global.scss';

function App() {
  return (
    <RouterProvider router={router}/>
  );
}
export default App;
