import { configureStore } from "@reduxjs/toolkit";

import { api } from 'shared/api/baseApi';

import reducer from 'app/store/reducer';

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(api.middleware),
        devTools: process.env.NODE_ENV === 'development'
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
