import { useEffect } from 'react';
//fix
import { useAppSelector, useAppDispatch } from "app/store/hooks";

import { setVisible } from "shared/lib/store/success";

import styles from 'entities/successModal/ui/SuccessModal.module.scss';

import success from 'entities/successModal/ui/success.gif';

export const SuccessModal = () => {
    const dispatch = useAppDispatch();
    const visible = useAppSelector(state => state.successSlice.isVisible);

    useEffect(() => {
        const timeout = setTimeout(() => dispatch(setVisible(false)), 2000);
        return () => clearTimeout(timeout);
    }, [visible]);
    return (
        <div className={visible ? styles.modal_active : styles.modal}>
            <img src={success} alt={'успешно'} className={styles.img}/>
            <span className={styles.text}>Успешно!</span>
        </div>

    );
};
