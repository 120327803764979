import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import {  Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';

//fix
import { useAppDispatch } from "app/store/hooks";

import { Button, CloseButton, InputDate, Overlay, TextArea, FormError } from "shared/ui";
import { setVisible } from "shared/lib/store/success";

import { useCreateCommentMutation} from "features/comment/api";
import { IComment } from "features/comment/types";

import styles from 'features/comment/ui/CommentForm.module.scss';

export const CommentForm = () => {
    const dispatch = useAppDispatch();
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const [ createComment ] = useCreateCommentMutation();

    const handleSubmit = async (values: IComment) => {
        await createComment(values).unwrap()
            .then((res) => {
                if(res) {
                    dispatch(setVisible(true));
                    setTimeout(() => navigate(-1), 2000);
                } else {
                    setError(true);
                }
            });
    };

    return (
        <>
            <Overlay trigger={true}/>
            <Formik
                initialValues={{
                    date: '',
                    comment: ''
                }}
                validationSchema={Yup.object({
                    date:  Yup.string()
                        .required('Выберите дату'),
                    comment: Yup.string()
                        .required('Обязательное поле!'),
                })}
                onSubmit={(values) => {
                    handleSubmit(values);
                }}
            >
                <Form className={styles.form_active}>
                    <h2 className={styles.title}>Комментарий</h2>
                    <CloseButton onClick={() => navigate(-1)}/>
                    <Row>
                        <Col lg={12}>
                            <InputDate
                                name={'date'}
                                label={'Укажите дату'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <TextArea
                                type={'text'}
                                name={'comment'}
                                placeholder={''}
                                label={'Комментарий'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Button color={'blue'} text={'Отправить'}/>
                        </Col>
                    </Row>
                    <Row>
                        <FormError text={'Не найден рабочий день в выбранную дату'} visible={error}/>
                    </Row>
                </Form>
            </Formik>
        </>
    );
};