export const findPath = (path:string, name: string) => {
    switch(path) {
        case 'profile':
            return 'Личный кабинет';
        case 'home':
            return `Привет, ${name}`;
        case 'coworkers':
            return `Мои коллеги`;
        case 'duty':
            return `Дежурство`;
        case 'comment':
            return `Комментарий`;
        case 'requests':
            return `Заявки`;
        case 'auth':
            return 'Привет, мы тебя не узнали. Авторизируйся в системе.';
        default:
            return 'Страница не найдена';
    }
};
