import { api } from 'shared/api/baseApi';
import { IUser, IUserWithToken } from "shared/types/IUser";

import { IAuthUser } from "features/auth/types";

export const authApi = api.injectEndpoints({
    endpoints: (build) =>  ({
        authUser: build.mutation<IUserWithToken, IAuthUser>({
            query: (user) => ({
                url: 'login',
                method: 'POST',
                body: user,
                credentials: 'include'
            })
        })
    })
});
export const { useAuthUserMutation
} = authApi;
