//fix this
import { useAppDispatch } from 'app/store/hooks';

import { setPasswordFormVisible } from 'features/profile/edit/model';

import styles from 'features/profile/edit/ui/PasswordChangeForm.module.scss';

export const PasswordChangeButton = () => {
    const dispatch = useAppDispatch();
    return <span className={styles.change} onClick={() => dispatch(setPasswordFormVisible())}>Изменить</span>;
};
