import { FC } from "react";

import styles from "entities/user/ui/UserQuote.module.scss";

interface Props {
    quote: string | undefined | null
}
export const UserQuote:FC<Props> = ({quote}) => {
    return (
        <div className={styles.quote}>
            <span className={styles.title}>Цитата:</span>
            <span className={styles.info}>«{quote ? quote : 'Цитата не задана'}»</span>
        </div>
    );
};
