import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type state = {
    coworkerId: number | null,
    isCoworkerPopupOpen: boolean
};
const initialState:state = {
    coworkerId: null,
    isCoworkerPopupOpen: false
};

export const coworkerSlice = createSlice({
    name: 'coworkerSlice',
    initialState,
    reducers: {
        setCoworkerPopupVisible: (state, action:PayloadAction) => {
            state.isCoworkerPopupOpen = !state.isCoworkerPopupOpen;
        },
        setCoworkerId: (state, action:PayloadAction<number>) => {
            state.coworkerId = action.payload;
        }
    }
});

export const { setCoworkerPopupVisible, setCoworkerId} = coworkerSlice.actions;
export default  coworkerSlice.reducer;

