
import { IComment, IResponseComment } from "features/comment/types";

import { api } from 'shared/api/baseApi';

export const registrationApi = api.injectEndpoints({
    endpoints: (build) =>  ({
        createComment: build.mutation<IResponseComment | boolean, IComment>({
            query: (data) => ({
                url: 'add_comment ',
                method: 'POST',
                body: data,
                credentials: 'include'
            })
        }),
    })
});
export const {
    useCreateCommentMutation
} = registrationApi;



