import { FC } from 'react';

import { setNameToAvatar } from "shared/lib";

import styles from "entities/user/ui/UserThumbnail.module.scss";

interface Props {
    photo: string | null | undefined,
    firstname: string,
    lastname: string
}
export const UserThumbnail:FC<Props> = ({photo, firstname, lastname}) => {
    return (
        photo ? (
            <div className={styles.avatar}>
                <img src={photo} alt={'аватар'}/>
            </div>
        ) : (
            <div className={styles.thumbnail}>
                {setNameToAvatar(firstname, lastname)}
            </div>
        )
    );
};
