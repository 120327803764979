import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialStateType  {
    isOpen: boolean
}

const initialState:initialStateType = {
    isOpen: false
};
export const layoutSlice = createSlice({
    name: 'layoutSlice',
    initialState,
    reducers: {
        changeVisible: (state, action:PayloadAction) => {
            state.isOpen = !state.isOpen;
        },
        closeBurger: (state, action:PayloadAction) => {
            state.isOpen = false;
        }
    }
});
export const {changeVisible, closeBurger} = layoutSlice.actions;
export default layoutSlice.reducer;
