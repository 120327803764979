import { useLocation } from "react-router-dom";

import { useAuth } from "shared/lib/hooks";
import { getUserWorkplace } from "shared/lib";

import { findPath } from "entities/headerInfo/lib/findPath";

// fix несоответсвие FSD
import { useAppSelector } from "app/store/hooks";

import styles from "entities/headerInfo/ui/HeaderInfo.module.scss";

export const HeaderInfo =  () => {
    const location = useLocation();
    const isAuth = useAuth();

    const stateWorkplace = useAppSelector(state => state.userSlice.userWorkplace);

    const data = JSON.parse(localStorage.getItem('User') || '{}');
    let path = location.pathname;
    path = path.replace(/[^a-zа-яё0-9\s]/gi, '');

    if(path.length === 0) {
        path = 'home';
    }

    return (
        <div className={styles.title}>
            <h2>{findPath(path, data?.firstname)}</h2>
            { isAuth ? <span className={styles.workplace}>Ваше рабочее место:<span> {getUserWorkplace(stateWorkplace)}</span></span> : null}
        </div>
    );
};
