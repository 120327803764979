import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import type { BaseQueryFn, FetchArgs, FetchBaseQueryError} from '@reduxjs/toolkit/query';

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders (headers) {
        const token = localStorage.getItem('token');
        if(token) {
            headers.set('authorization', 'Bearer ' + token.replace(/["']/g, ""));
        }
        headers.set('Accept','application/json');

        return headers;
    },
});

export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions = {}) => {
    const result = await baseQuery(args, api, extraOptions);
    if (result?.error?.status === 401) {
        localStorage.clear();
        window.location.href = '/auth';
    }
    return result;
};

export const api = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReauth,
    endpoints: build => ({}),
});
