import { Container } from "react-bootstrap";

import { CoworkersList } from "widgets/coworkersList";

export const CoworkersPage = () => {
    return (
        <Container>
            <CoworkersList/>
        </Container>

    );
};

