import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type state = {
    isProfileFormVisible: boolean,
    isPasswordFormVisible: boolean,
    isAvatarFormVisible: boolean
};
const initialState:state = {
    isProfileFormVisible: false,
    isPasswordFormVisible: false,
    isAvatarFormVisible: false
};

export const profileEditSlice = createSlice({
    name: 'profileEditSlice',
    initialState,
    reducers: {
        setProfileFormVisible: (state, action:PayloadAction) => {
            state.isProfileFormVisible = !state.isProfileFormVisible;
        },
        setPasswordFormVisible: (state, action:PayloadAction) => {
            state.isPasswordFormVisible = !state.isPasswordFormVisible;
        },
        setAvatarFormVisible: (state, action:PayloadAction) => {
            state.isAvatarFormVisible = !state.isAvatarFormVisible;
        }
    }
});

export const {
    setProfileFormVisible,
    setPasswordFormVisible,
    setAvatarFormVisible
    } = profileEditSlice.actions;
export default  profileEditSlice.reducer;

