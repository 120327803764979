import { PersonalTimeReport, TotalTimeReport, FullTimeReport} from "features/timeReport";

import { useUserModules } from "shared/lib/hooks";

export const TimeReports = () => {
    const modules = useUserModules('full_report');
    return (
        <>
            <PersonalTimeReport/>
            {
                modules ? <>
                    <FullTimeReport/>
                    <TotalTimeReport/>
                </> : null
            }
        </>
    );
};