import { FC } from 'react';
import cnBind from 'classnames/bind';

import styles from './Button.module.scss';
interface ButtonProps  {
    color: string,
    text: string,
}
export const Button:FC<ButtonProps> = ({color, text}) => {
    const cx = cnBind.bind(styles);

    return <button className={cx(styles.button, color)} type="submit">{text}</button>;
};
