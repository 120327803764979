
import {BaseTimeReport} from "features/timeReport/ui/BaseTimeReport";

export const TotalTimeReport = () => {
    return (
        <BaseTimeReport
            url={'cabinet/download_total_report'}
            fileName={'отчет_факт_смен'}
            title={'Итоговый отчёт факт. смен сотрудников'}
        />
    );
};
