import { FC } from 'react';
import { Row, Col } from 'react-bootstrap';
import cnBind from "classnames/bind";

import { ICoworkersItem } from 'shared/types/ICoworkersItem';
import { setNameToAvatar } from "shared/lib";

import styles from 'entities/coworkersItem/ui/CoworkersItem.module.scss';

export const CoworkersItem:FC<ICoworkersItem> = ({id, firstname, lastname, email, phone, quote, department, position, verified, photo, birthday, status, workplace }) => {
    const cx = cnBind.bind(styles);
    return (
        <div className={styles.item}>
            <Row className={styles.row}>
                <Col xl={3} lg={5} md={7} sm={6} xs={12}>
                    <div className={styles.block}>
                        {
                            photo ? (
                                <div className={styles.avatar}>
                                    <img src={photo} alt={'аватар'}/>
                                </div>
                            ) : (
                                <div className={styles.thumbnail}>
                                    {setNameToAvatar(firstname, lastname)}
                                </div>
                            )
                        }
                        <div className={styles.info}>
                            <div className={styles.name_block}>
                                <span className={styles.text}>{lastname} {firstname}</span>
                                <span
                                    className={status === 'Офлайн' ? styles.offline : status === 'Онлайн' ? styles.online : styles.leave}>
                                </span>
                            </div>
                            <span className={styles.position}>{position.name}</span>
                        </div>
                    </div>

                </Col>
                <Col xl={1} className={'d-xl-block d-none'}>
                    <span className={styles.text}>{department.name}</span>
                </Col>
                <Col xl={2} lg={3} md={3} className={'d-md-block d-none'}>
                    <span className={styles.text}>{phone}</span>
                </Col>
                <Col xl={2} lg={2} className={'d-lg-block d-none'}>
                    <span className={styles.text}>{birthday}</span>
                </Col>
                <Col xl={1} lg={2} md={2} className={'d-md-block d-none'}>
                    <span className={styles.text}>{workplace}</span>
                </Col>
                <Col xl={3} className={'d-xl-block d-none'}>
                    <span className={styles.quote}>«{quote}»</span>
                </Col>
            </Row>
        </div>
    );
};
