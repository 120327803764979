import loader from 'shared/ui/loader/loader.gif';

import styles from 'shared/ui/loader/Loader.module.scss';
export const Loader = () => {
    return (
        <div className={styles.block}>
            <img className={styles.img} src={loader} alt={'Загрузка...'}/>
            <span className={styles.text}>Загрузка...</span>
        </div>
    );
};
