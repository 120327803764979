import { FC } from 'react';
import styles from 'shared/ui/closeButton/CloseButton.module.scss';

type CloseButtonProps = {
    onClick: () => void;
}

// по сути, это фича(кнопка закрытия попапов), но т.к. фича не может использовать фичу, пришлось вынести этот компонент в shared. Необходимо что то придумать с этим.
export const CloseButton:FC<CloseButtonProps> = ({onClick}) => {
    return (
        <div className={styles.close} onClick={onClick}></div>
    );
};
