import React, { Component, ReactNode } from "react";

import { ErrorPlug } from 'shared/ui/errorPlug/ErrorPlug';

import errorGif from 'app/error/errorGif.gif';

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {hasError: false};
    }
     static getDerivedStateFromError() {
        return { hasError: true };
    }


    public render() {
        if (this.state.hasError) {
            return <ErrorPlug img={errorGif} text={'Что то пошло не так...'} link={false}/>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
