import { BaseTimeReport } from "features/timeReport/ui/BaseTimeReport";

export const PersonalTimeReport = () => {
    return (
        <BaseTimeReport
            url={'cabinet/download_personal_report'}
            fileName={'отчет_рабочего_времени'}
            title={'Отчёт рабочего времени'}
        />
    );
};
