
//пофиксить этот импорт
import {useAppDispatch} from 'app/store/hooks';

import { setAuthFormVisible} from 'features/auth';

import styles from 'features/auth/ui/AuthForm.module.scss';

export const AuthButton = () => {
    const dispatch = useAppDispatch();

    return (
        <button className={styles.button} onClick={() => dispatch(setAuthFormVisible())}>Авторизация</button>
    );
};
