import React, { FC } from 'react';

import { ErrorMessage, Field } from 'formik';

import styles from './Select.module.scss';

type SelectProps = {
    name: string,
    label: string,
    children?: string | JSX.Element | JSX.Element[],
    onClick?: (value: React.ChangeEvent<HTMLSelectElement>) => void,
    defaultOption?: boolean
};
export const Select:FC<SelectProps> = ({onClick, children, name, label, defaultOption}) => {
    return (
        <>
            <label htmlFor={name} className={styles.label}>{label}</label>
            <Field as="select" className={styles.input} name={name} onClick={onClick}>
                {defaultOption ? <option>Выберите {label}</option> : null}
                {children}
            </Field>
            <ErrorMessage component="div" className={styles.error} name={name}/>
        </>
    );
};
