import { api } from 'shared/api/baseApi';

import { ICoworkersItem } from 'shared/types/ICoworkersItem';

export const authApi = api.injectEndpoints({
    endpoints: (build) =>  ({
        getUsers: build.query<ICoworkersItem[], void>({
            query: () => ({
                url: 'get_users',
                credentials: "include"
            })
        }),
    })
});
export const { useGetUsersQuery
} = authApi;



