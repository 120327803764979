import type { SVGProps } from "react";
const SvgComment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2.5em"
    height="2.5em"
    fill="none"
    viewBox="0 0 36 35"
    {...props}
  >
    <g clipPath="url(#comment_svg__a)">
      <path
        fill="#E2E2E2"
        d="M4.875 25.156v5.469h5.469l16.129-16.13-5.469-5.468-16.129 16.13Zm4.258 2.552H7.792v-1.341l13.212-13.213 1.342 1.342L9.133 27.708ZM30.703 8.21 27.29 4.798a1.432 1.432 0 0 0-1.036-.423c-.379 0-.744.146-1.02.423l-2.67 2.669 5.47 5.468 2.668-2.668a1.452 1.452 0 0 0 0-2.057Z"
      />
    </g>
    <defs>
      <clipPath id="comment_svg__a">
        <path fill="#fff" d="M.5 0h35v35H.5z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComment;
