//fix this import!
import { useAppDispatch } from "app/store/hooks";

import { setAvatarFormVisible } from "features/profile/edit/model";

import { setNameToAvatar } from "shared/lib";

import styles from "features/profile/edit/ui/UserAvatar.module.scss";
export const UserAvatar = () => {
    const dispatch = useAppDispatch();
    const data = JSON.parse(localStorage.getItem('User') || '{}');

    return (
        <div className={styles.name}>
            {data.photo ? (
                <div className={styles.avatar} onClick={() => dispatch(setAvatarFormVisible())}>
                    <img src={data.photo} alt={'аватар'}/>
                </div>
            ) : (
                <div className={styles.thumbnail} onClick={() => dispatch(setAvatarFormVisible())}>{ setNameToAvatar(data.firstname, data.lastname)}</div>
            )}
            <span>{data.lastname} {data.firstname}</span>
        </div>
    );
};
