import { FC } from 'react';

//fix
import { useAppDispatch } from "app/store/hooks";

import { setCoworkerId, setCoworkerPopupVisible } from "features/coworkers/model";

import styles from "features/coworkers/ui/CoworkerArrow.module.scss";

interface Props {
    id: number
}
export const CoworkerArrow:FC<Props> = ({ id}) => {
    const dispatch = useAppDispatch();

    return (
        <div className={styles.arrow} onClick={() => {
            dispatch(setCoworkerId(id));
            dispatch(setCoworkerPopupVisible());
        }
        }></div>
    );
};
