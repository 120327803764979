//пофиксить этот импорт
import {useAppDispatch} from "app/store/hooks";

import { setRegistrationFormVisible} from "features/registration";

import styles from 'features/registration/ui/RegistrationForm.module.scss';

export const RegistrationButton = () => {
    const dispatch = useAppDispatch();

    return (
        <button className={styles.button} onClick={() => dispatch(setRegistrationFormVisible())}>Регистрация</button>
    );
};
