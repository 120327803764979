import { Container } from "react-bootstrap";
import inDevelopment from 'pages/inDevelopment/ui/inDevelopment.gif';
import {PagePlug} from "shared/ui/pagePlug";

export const InDevelopmentPage = () => {
    return (
        <Container>
            <PagePlug text={'Страница находится в разработке'} img={inDevelopment}/>
        </Container>
    );
};