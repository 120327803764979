export const months = [
    {
        title: 'Январь',
        value: 1
    },
    {
        title: 'Февраль',
        value: 2
    },
    {
        title: 'Март',
        value: 3
    },
    {
        title: 'Апрель',
        value: 4
    },
    {
        title: 'Май',
        value: 5
    },
    {
        title: 'Июнь',
        value: 6
    },
    {
        title: 'Июль',
        value: 7
    },
    {
        title: 'Август',
        value: 8
    },
    {
        title: 'Сентябрь',
        value: 9
    },
    {
        title: 'Октябрь',
        value: 10
    },
    {
        title: 'Ноябрь',
        value: 11
    },
    {
        title: 'Декабрь',
        value: 12
    },
];
