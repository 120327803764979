import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type state = {
    isAuthFormVisible: boolean,
};
const initialState:state = {
    isAuthFormVisible: false,
};

export const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        setAuthFormVisible: (state, action:PayloadAction) => {
            state.isAuthFormVisible = !state.isAuthFormVisible;
        }
    }
});

export const { setAuthFormVisible} = authSlice.actions;
export default  authSlice.reducer;

