import { api } from "shared/api/baseApi";

import { layoutSlice } from "widgets/layout/model/layoutSlice";
import { profileEditSlice } from "features/profile/edit";
import { registrationSlice} from "features/registration";
import { authSlice } from "features/auth";
import { userSlice } from "shared/lib/store/user";
import { successSlice } from "shared/lib/store/success";
import { coworkerSlice } from 'features/coworkers/model';

const reducer = {
    [api.reducerPath] : api.reducer,
    layoutSlice: layoutSlice.reducer,
    profileEditSlice: profileEditSlice.reducer,
    registrationSlice: registrationSlice.reducer,
    authSlice: authSlice.reducer,
    userSlice: userSlice.reducer,
    successSlice: successSlice.reducer,
    coworkerSlice: coworkerSlice.reducer,
};
export default reducer;
