import type { SVGProps } from "react";
const SvgBugTracker = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="2.5em"
    height="2.5em"
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 9.85 9.85"
    {...props}
  >
    <defs>
      <style>{".bugTracker_svg__fil0{fill:none}"}</style>
    </defs>
    <g id="bugTracker_svg__\u0421\u043B\u043E\u0439_x0020_1">
      <rect
        width={9.2}
        height={9.2}
        x={0.32}
        y={0.32}
        className="bugTracker_svg__fil0"
        rx={2.08}
        ry={2.08}
        style={{
          stroke: "#fff",
          strokeWidth: 0.65,
          strokeMiterlimit: 22.9256,
        }}
      />
      <g id="bugTracker_svg___2715269326112">
        <path
          d="m2.22 5.49 1.12-1.12m-1.12 0 1.12 1.12M2.37 2.52l.41.41.79-.79M2.37 7.29l.41.41.79-.78M4.84 2.65h2.79M4.84 4.99h2.79M4.84 7.33h2.79"
          className="bugTracker_svg__fil0"
          style={{
            stroke: "#fff",
            strokeWidth: 0.65,
            strokeLinecap: "round",
            strokeMiterlimit: 22.9256,
          }}
        />
      </g>
    </g>
  </svg>
);
export default SvgBugTracker;
