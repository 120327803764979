import {createSlice, PayloadAction} from "@reduxjs/toolkit";


interface state {
    isRegistrationFormVisible: boolean,
}

const initialState:state = {
    isRegistrationFormVisible: false,
};

export const registrationSlice = createSlice({
    name: 'registrationSlice',
    initialState,
    reducers: {
        setRegistrationFormVisible: (state, action:PayloadAction) => {
            state.isRegistrationFormVisible = !state.isRegistrationFormVisible;
        }
    }
});

export const { setRegistrationFormVisible} = registrationSlice.actions;
export default  registrationSlice.reducer;

