import { FC, useState } from 'react';

import { ErrorMessage, Field } from 'formik';

import { InputPasswordProps } from "shared/ui/input/types";
import { HideEye, ShowEye } from "shared/ui/icons";

import styles from 'shared/ui/input/Input.module.scss';


export const InputPassword:FC<InputPasswordProps> = ({ type, placeholder, name, label, onClick}) => {
     const [isVisible, setVisible] = useState(false);

    const toggle = () => {
        setVisible(!isVisible);
    };
    return (
        <div className={styles.block}>
            <label htmlFor={name} className={styles.label}>{label}</label>
            <Field className={styles.input}  name={name} type={isVisible ? "text" : "password"} placeholder={placeholder}></Field>
            {isVisible ? <HideEye className={styles.icon} onClick={toggle}/> : <ShowEye className={styles.icon} onClick={toggle}/>}
            <ErrorMessage component="div" className={styles.error} name={name}/>
        </div>
    );
};

