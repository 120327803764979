import { FC, useEffect } from 'react';
import cnBind from "classnames/bind";
//fix
import { useAppSelector, useAppDispatch} from "app/store/hooks";
import { Overlay, CloseButton } from 'shared/ui';
import { ICoworkersItem } from 'shared/types/ICoworkersItem';
import { UserThumbnail } from "entities/user";
import { UserInfo, UserQuote } from "entities/user";

import { setCoworkerPopupVisible } from "features/coworkers/model";

import styles from 'features/coworkers/ui/CoworkerPopup.module.scss';

interface Props {
    coworkers: ICoworkersItem[] | undefined;
}
const cx = cnBind.bind(styles);
export const CoworkerPopup:FC<Props> = ({coworkers}) => {
    const dispatch = useAppDispatch();
    const coworker = useAppSelector(state => state.coworkerSlice.coworkerId);
    const visible = useAppSelector(state => state.coworkerSlice.isCoworkerPopupOpen);

    useEffect(() => {
        visible ?  document.body.style.overflow = 'hidden' :  document.body.style.overflow = '';
    }, [visible]);

    const coworkerInfo = coworkers?.find(item => item.id === coworker);
    return (
       <>
           <Overlay trigger={visible}/>
           <div className={visible ? styles.wrapper_active : styles.wrapper}>
               <CloseButton onClick={() => dispatch(setCoworkerPopupVisible())}/>
               <div className={styles.info}>
                   <UserThumbnail photo={coworkerInfo?.photo} firstname={'Жуйко'} lastname={'Миша'}/>
                    <div className={styles.name_block}>
                        <span className={styles.name}>{coworkerInfo?.lastname} {coworkerInfo?.firstname}</span>
                        <span className={coworkerInfo?.status === 'Офлайн' ? styles.offline : coworkerInfo?.status === 'Онлайн' ? styles.online : styles.leave} ></span>
                    </div>
               </div>
               <div className={styles.outer}>
                   <div className={styles.block}>
                       <UserInfo title={'Рабочее место'} info={coworkerInfo?.workplace}/>
                   </div>
                   <div className={styles.block}>
                       <UserInfo title={'Отдел'} info={coworkerInfo?.department.name}/>
                   </div>
                   <div className={styles.block}>
                       <UserInfo title={'Должность'} info={coworkerInfo?.position.name}/>
                   </div>
                   <div className={styles.block}>
                       <UserInfo title={'Телефон'} info={coworkerInfo?.phone}/>
                   </div>
                   <div className={styles.block}>
                       <UserInfo title={'Почта'} info={coworkerInfo?.email}/>
                   </div>
                   <div className={styles.block}>
                       <UserInfo title={'Дата рождения'} info={coworkerInfo?.birthday}/>
                   </div>
                   <div className={styles.block}>
                       <UserQuote quote={coworkerInfo?.quote}/>
                   </div>
               </div>
           </div>
       </>
    );
};
