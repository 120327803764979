import { FC, ReactNode } from 'react';
import { Link } from "react-router-dom";

import styles from 'features/navLink/ui/NavLink.module.scss';

interface Props {
    text: string,
    children: ReactNode,
    link: string,
    onClick: () => void,
    target?: string
}
export const NavLink:FC<Props> = ({text, children, onClick, link, target}) => {
    return (
        <Link to={link} className={styles.block} onClick={onClick} target={target}>
            {children}
            <span  className={styles.text}>{text}</span>
        </Link>
    );
};
