import {Col, Row} from "react-bootstrap";
import {Form, Formik, FormikProps, FormikValues } from "formik";
import * as Yup from "yup";
import { useState, useRef } from "react";

import { useAppDispatch, useAppSelector} from "app/store/hooks";

import {Button, CloseButton, InputFile, Overlay} from "shared/ui";
import {setUser} from "shared/lib/store/user";

import {setAvatarFormVisible} from "features/profile/edit/model";
import { useEditAvatarMutation } from 'features/profile/edit/api';

import styles from 'features/profile/edit/ui/UserAvatar.module.scss';


export const UserAvatarForm = () => {
    // fix type
    const formikRef = useRef<any>();
    //
    const [ file, setFile] = useState<File>();
    const dispatch = useAppDispatch();
    const visible = useAppSelector(state => state.profileEditSlice.isAvatarFormVisible);
    const [ editAvatar ] = useEditAvatarMutation();



    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        formikRef.current.setFieldValue('photo', e.target.files?.[0]);
        setFile(e.target.files?.[0]);
    };

    const handleSubmit = async () => {
        const data = new FormData();
        data.append('photo', file as Blob);
        await editAvatar(data).unwrap()
            .then(res => {
                dispatch(setUser(res));
                localStorage.setItem('User', JSON.stringify(res));
                dispatch(setAvatarFormVisible());
            });
    };
    return (
        <>
            <Overlay trigger={visible}/>
            <Formik
                innerRef={formikRef}
                initialValues={{
                    photo: '',
                }}
                validationSchema={Yup.object().shape({
                    photo: Yup.mixed()
                        .required('Обязательное поле')

                        .test("type", "Формат не поддерживается", (value: any) => {
                            return value && (
                                value.type === "image/jpeg" ||
                                value.type === "image/jpg" ||
                                value.type === "image/png"
                            );
                        }),
                })}
                onSubmit={(value, {resetForm}) => {
                    handleSubmit();
                    resetForm();
                }}
            >
                <Form className={visible ? styles.form_active : styles.form}>
                    <h2 className={styles.title}>Редактирование аватара</h2>
                    <CloseButton onClick={() => dispatch(setAvatarFormVisible())}/>
                    <Row>
                        <Col lg={12}>
                            <InputFile name={'photo'} label={'Аватар'} type={'file'}  onChange={handleChange} multiple={false}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Button text={'Сохранить'} color={'blue'}/>
                        </Col>
                    </Row>
                </Form>
            </Formik>
        </>
    );
};
