
import styles from 'shared/ui/formError/FormError.module.scss';

interface IErrorProps {
    text: string,
    visible: boolean
}
export const FormError = ({text, visible}:IErrorProps) => {
    return <span className={visible ? styles.error_active : styles.error}>{text}</span>;
};
