import { FC } from 'react';
import * as Yup from 'yup';
import {  Row, Col } from 'react-bootstrap';
import { Formik, Form, } from 'formik';

import { useAppDispatch, useAppSelector } from "app/store/hooks";
import { InputPassword, Button, CloseButton, Overlay } from "shared/ui";

import {setPasswordFormVisible} from 'features/profile/edit/model';
import { useEditPasswordMutation } from "features/profile/edit/api";
import { IUserEditPassword } from "features/profile/edit/types";

import styles from 'features/profile/edit/ui/PasswordChangeForm.module.scss';


export const PasswordChangeForm:FC = () => {

    const dispatch = useAppDispatch();
    const visible = useAppSelector(state => state.profileEditSlice.isPasswordFormVisible);

    const [editPassword] = useEditPasswordMutation();

    const handleSubmit = async (values: IUserEditPassword) => {
        await editPassword(values).unwrap()
            .then(() => dispatch(setPasswordFormVisible()));
    };

    return (
        <>
            <Overlay trigger={visible}/>
            <Formik
                initialValues={{
                current_password: '',
                password: '',
                password_confirmation: ''
                }}
                validationSchema={Yup.object({
                    current_password: Yup.string()
                        .required('Обязательное поле'),
                    password: Yup.string()
                        .required('Обязательное поле')
                        .min(6, 'Минимум 6 символов'),
                    password_confirmation: Yup.string()
                        .required('Обязательное поле')
                        .oneOf([Yup.ref('password')], 'Пароли не совпадают')

                })}
                onSubmit={(values, {resetForm}) => {
                    handleSubmit(values);
                    resetForm();
                }}
            >
                <Form className={visible ? styles.form_active : styles.form}>
                    <h2 className={styles.title}>Изменение пароля</h2>
                    <CloseButton onClick={() => dispatch(setPasswordFormVisible())}/>
                    <Row>
                        <Col lg={12}>
                            <InputPassword name={'current_password'} label={'Введите старый пароль'}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <InputPassword name={'password'} label={'Введите новый пароль'}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <InputPassword name={'password_confirmation'} label={'Введите новый пароль ещё раз'}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Button text={'Сохранить'} color={'blue'}/>
                        </Col>
                    </Row>
                </Form>
            </Formik>
        </>
    );
};
