import { FC, useState, useEffect } from 'react';

import { useAppDispatch } from "app/store/hooks";

import { useGetButtonPositionQuery, useStartDayMutation, usePauseDayMutation, useResumeDayMutation, useEndDayMutation } from 'features/manageDay/api';
import { ManageDayButton } from "features/manageDay/ui/ManageDayButton";

import { setUserWorkplace } from "shared/lib/store/user";

import styles from 'features/manageDay/ui/ManageDay.module.scss';
export const ManageDay:FC = () => {
    const dispatch = useAppDispatch();
    const [dayInfo, setDayInfo ] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const { data, refetch } = useGetButtonPositionQuery(dayInfo);


    useEffect(() => {
        setIsDisabled(false);
        dispatch(setUserWorkplace(data?.workplace));
        localStorage.setItem('workplace', data?.workplace);
    }, [data]);

    useEffect(() => {
        refetch();
    }, []);

    const [ startDay ] = useStartDayMutation();
    const [ endDay ] = useEndDayMutation();
    const [ pauseDay ] = usePauseDayMutation();
    const [ resumeDay ] = useResumeDayMutation();


    const setStartDay = async (workplace_id: number) => {
        setIsDisabled(true);
        await startDay({"workplace_id": workplace_id})
            .then(() => setDayInfo('user at work'));
    };
    const setEndDay = async () => {
        setIsDisabled(true);
        await endDay({"without_lunch": false})
            .then(() => setDayInfo('user end work'));
    };

    const setPauseDay = async () => {
        setIsDisabled(true);
        await pauseDay()
            .then(() => refetch());
    };
    const setResumeDay = async (workplace_id: number) => {
        setIsDisabled(true);
        await resumeDay({"workplace_id": workplace_id})
            .then(() => refetch());
    };

    const manageDayButtons = (buttonPosition: string) => {
        switch (buttonPosition) {
            case 'User has not started the working day':
                return (
                    <div className={styles.block}>
                        <ManageDayButton color='blue' text={'Начать рабочий день в офисе'} disabled={isDisabled} onClick={() => setStartDay(1)} icon={'office'}/>
                        <ManageDayButton color='dark' text={'Начать рабочий день дома'} disabled={isDisabled} onClick={() => setStartDay(2)} icon={'weekend'}/>
                    </div>
                );
            case 'User at work':
                return (
                    <div className={styles.block}>
                        <ManageDayButton color='green' text={'Отойти с рабочего места'} disabled={isDisabled} onClick={() => setPauseDay()} icon={'leave'}/>
                        <ManageDayButton color='blue' text={'Закончить рабочий день'} disabled={isDisabled} onClick={() => setEndDay()} icon={'weekend'}/>
                    </div>
                );

            case 'User has leaved from working place':
                return (
                    <div className={styles.block}>
                        <ManageDayButton color='green' text={'Вернуться на рабочее место'} disabled={isDisabled} onClick={() => setResumeDay(1)} icon={'office'}/>
                        <ManageDayButton color='blue' text={'Продолжить дома'} disabled={isDisabled} onClick={() => setResumeDay(2)} icon={'weekend'}/>
                    </div>
                );
            case 'User already ended working day':
                return (
                    <div className={styles.block}>
                        <ManageDayButton color='blue_opacity' text={'Начать рабочий день в офисе'} disabled={true} icon={'office'}/>
                        <ManageDayButton color='blue_opacity' text={'Начать рабочий день дома'} disabled={true} icon={'home'}/>
                    </div>
                );
        }
    };


    const buttons =  manageDayButtons(data?.position);
    return (
       <>
           {buttons}
       </>
    );
};
