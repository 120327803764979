import type { SVGProps } from "react";
const SvgDuty = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2.5em"
    height="2.5em"
    fill="none"
    viewBox="0 0 36 35"
    {...props}
  >
    <g clipPath="url(#duty_svg__a)">
      <path
        fill="#E2E2E2"
        d="M28.208 4.375H26.75V1.458h-2.917v2.917H12.167V1.458H9.25v2.917H7.792a2.925 2.925 0 0 0-2.917 2.917v20.416a2.925 2.925 0 0 0 2.917 2.917h20.416a2.925 2.925 0 0 0 2.917-2.916V7.291a2.925 2.925 0 0 0-2.917-2.917Zm0 23.334H7.792V13.125h20.416V27.71Zm-20.416-17.5V7.292h20.416v2.917H7.792Zm2.916 5.833h14.584v2.916H10.708v-2.916Zm0 5.833h10.209v2.917H10.708v-2.917Z"
      />
    </g>
    <defs>
      <clipPath id="duty_svg__a">
        <path fill="#fff" d="M.5 0h35v35H.5z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgDuty;
