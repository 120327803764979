import { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import {  Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

import {setAuthFormVisible} from "features/auth/model";
//пофиксить этот импорт, не соотвествие FSD
import { useAppDispatch, useAppSelector } from "app/store/hooks";
import { Overlay, Input, InputPassword, CloseButton, Button, FormError } from "shared/ui";

import { setUser } from "shared/lib/store/user";

import { useAuthUserMutation } from "features/auth/api";
import { IAuthUser } from 'features/auth/types';

import styles from 'features/auth/ui/AuthForm.module.scss';

export const AuthForm = () => {

    const [error, setError] = useState(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [authUser] = useAuthUserMutation();

    const visible = useAppSelector(state => state.authSlice.isAuthFormVisible);

    const handleSubmit = async (values: IAuthUser ) => {
        await authUser(values).unwrap()
            .then(res => {
                if(res) {
                    localStorage.setItem('User', JSON.stringify(res.user));
                    localStorage.setItem('token', JSON.stringify(res.token));
                    dispatch(setAuthFormVisible());
                    dispatch(setUser(res.user));
                    navigate("/");
                } else {
                    setError(true);
                }
            });
    };

    return (
        <>

            <Overlay trigger={visible}/>
            <Formik
                initialValues={{
                    login: '',
                    password: ''
                }}
                validationSchema={Yup.object({
                    login:  Yup.string()
                        .required("Обязательное поле!")
                        .test('test-name', 'Неверный или не зарегистрированный логин ',
                            function(value) {
                                const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

                                const phoneRegex = /^(\+91-|\+91|0)?\d{11}$/;
                                const isValidEmail = emailRegex.test(value);
                                const isValidPhone = phoneRegex.test(value);
                                if (!isValidEmail && !isValidPhone ){
                                    return false;
                                }
                                return true;
                            }),
                    password: Yup.string()
                        .required('Обязательное поле!'),
                })}
                onSubmit={(values) => {
                    handleSubmit(values);
                }}
            >
                <Form className={visible ? styles.form_active : styles.form}>
                    <h2 className={styles.title}>Авторизация</h2>
                    <CloseButton onClick={() => dispatch(setAuthFormVisible())}/>
                    <Row>
                        <Col lg={12}>
                            <Input
                                type={'text'}
                                name={'login'}
                                placeholder={'ivan@gmail.com'}
                                label={'Логин'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <InputPassword
                                type={'password'}
                                name={'password'}
                                placeholder={''}
                                label={'Пароль'}
                            />
                        </Col>
                    </Row>
                    <Row>
                       <FormError text={'Неверный логин или пароль'} visible={error}/>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Button color={'blue'} text={'Войти'}/>
                        </Col>
                    </Row>
                </Form>
            </Formik>
        </>
    );
};
