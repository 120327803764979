import styles from "shared/ui/pagePlug/PagePlug.module.scss";

interface Props {
    text: string,
    img: string
}
export const PagePlug = ({text, img}: Props) => {
    return (
        <div className={styles.block}>
            <img className={styles.img} src={img} alt={text}/>
            <span className={styles.text}>{text}</span>
        </div>
    );
};