import { FC } from 'react';

import { ErrorMessage, Field } from 'formik';

import { InputFileProps } from "shared/ui/input/types";
import styles from 'shared/ui/input/Input.module.scss';

export const InputFile:FC<InputFileProps> = ({ type, placeholder, name, label, value, multiple, onChange}) => {
    return (
        <>
            <label htmlFor={name} className={styles.label}>{label}</label>
            <Field className={styles.input}  name={name} type={type} placeholder={placeholder} value={value} onChange={onChange} multiple={multiple}></Field>
            <ErrorMessage component="div" className={styles.error} name={name}/>
        </>
    );
};

