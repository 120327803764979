import type { SVGProps } from "react";
const SvgRequests = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2.5em"
    height="2.5em"
    fill="none"
    viewBox="0 0 36 35"
    {...props}
  >
    <g clipPath="url(#requests_svg__a)">
      <path
        fill="#E2E2E2"
        d="M28.208 2.917h-6.095C21.5 1.225 19.896 0 18 0c-1.896 0-3.5 1.225-4.113 2.917H7.792a2.925 2.925 0 0 0-2.917 2.916v23.334a2.925 2.925 0 0 0 2.917 2.916h20.416a2.925 2.925 0 0 0 2.917-2.916V5.833a2.925 2.925 0 0 0-2.917-2.916ZM18 2.917c.802 0 1.458.656 1.458 1.458 0 .802-.656 1.458-1.458 1.458a1.463 1.463 0 0 1-1.458-1.458c0-.802.656-1.458 1.458-1.458Zm10.208 26.25H7.792V5.833h2.916v4.375h14.584V5.833h2.916v23.334Z"
      />
    </g>
    <defs>
      <clipPath id="requests_svg__a">
        <path fill="#fff" d="M.5 0h35v35H.5z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgRequests;
