import { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from 'shared/ui/errorPlug/ErrorPlug.module.scss';

interface Props {
    text: string,
    img: string,
    link?: boolean
}

export const ErrorPlug:FC<Props> = ({text, img, link}) => {
    return (
        <div className={styles.block}>
            <img className={styles.img} src={img} alt='error'/>
            <span className={styles.text}>{text}</span>
            {link ? <Link to='/' className={styles.link}>Вернуться на главную</Link> : null}
        </div>
    );
};
