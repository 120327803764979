//fix
import { useAppDispatch} from 'app/store/hooks';

import { setProfileFormVisible } from 'features/profile/edit/model';

import styles from 'features/profile/edit/ui/ProfileEditForm.module.scss';

export const ProfileEditButton = () => {
    const dispatch = useAppDispatch();
    return <button className={styles.button} onClick={() => dispatch(setProfileFormVisible())}>Редактировать</button>;
};
